<template>
  <v-card max-width="400" class="mx-auto">
  <v-container>
    <v-row dense >
      <v-col cols="12">
        <v-card color="385F73">
          <v-img
            :src="require(`@/assets/tigre/card-top.jpg`)"
            class="white--text align-end"
            width="376px"
          >
          <v-card-text>{{ errors }}</v-card-text>
          </v-img>
          <v-card-text v-if="member.id">
            <v-row align="center">
              <vue-barcode
                :value="member.id"
                class="mx-auto"
                width="3"
              />
            </v-row>
          </v-card-text>
          <v-img
            :src="require(`@/assets/tigre/card-bottom_no_logo.jpg`)"
            class="white--text align-end"
            width="376px"
          >
            <v-card-title v-if="member.id" class="justify-end">{{ member.point || '0' }} pt</v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </v-card>
</template>

<script>
import VueBarcode from 'vue-barcode'

export default {
  components: {
    VueBarcode
  },

  data: () => ({
    loading: false,
    selection:  1,
    title: "会員証",
    member: {},
  }),

  props: {
    lineAuth: String
  },

  created() {
    this.axios.get(process.env.VUE_APP_ADMIN_URL+'/api/gambler/'+this.lineAuth, {line: this.lineAuth})
      .then( (response) => {
        console.log(response)
        this.member = response.data
      })
      .catch( (err) => {
        console.log(err)
      })
  },

  methods: {
    reserve() {
      this.loading = true
      setTimeout(() => (this.loading = false), 2000)
    }
  }
}
</script>
