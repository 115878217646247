<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <tigre-member-card
          v-if="lineAuth"
          :lineAuth="lineAuth"
        >
        </tigre-member-card>
        <!--
        <member-card
          v-if="lineAuth"
          :lineAuth="lineAuth"
        ></member-card>
        -->
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import liff from "@line/liff"
//import MemberCard from "./components/MemberCard.vue";
//import SampleCard from "@/components/SampleCard.vue";
import TigreMemberCard from "@/components/TigreMemberCard.vue";

export default {
  name: 'App',

  components: {
    //MemberCard,
    //SampleCard,
    TigreMemberCard,
  },

  data: () => ({
    loggedIn: false,
    profile: {},
    lineAuth: "",
  }),

  created() {
    liff
      .init({ liffId: process.env.VUE_APP_LIFF_ID })
      .then( () => {
        this.loggedIn = liff.isLoggedIn()
        if ( ! this.loggedIn) {
          liff.login()
        } else {
          this.getProfile()
          this.getLineAuth()
        }
      })
      .catch((err) => {
        this.occoredError = 'error: ' + err
      })
  },

  methods: {
    getProfile() {
      liff
        .getProfile()
        .then( profile => {
          this.profile = profile
        })
    },
    getLineAuth() {
      this.lineAuth = liff.getAccessToken()
    }

  }
};
</script>
